import Vue from "vue";
import Router from "vue-router";
import { getCookies } from "./service/utilsFunc";

Vue.use(Router);

const router = new Router({
	mode: "history",
	routes: [
		{
			path: "",
			component: () => import("./components/Menu.vue"),
			name: "menu",
			meta: {
				title: "SADT",
				requirestAuth: true,
			},
		},
		{
			path: "/login",
			component: () => import("./components/Login.vue"),
			name: "login",
			meta: {
				title: "SADT | login",
			},
		},
		{
			path: "/esqueci-senha",
			component: () => import("./components/ForgotPassword.vue"),
			name: "forgot-password",
			meta: {
				title: "SADT | Esqueci minha senha",
			},
		},
		{
			path: "/recupera-senha",
			component: () => import("./components/ResetPassword.vue"),
			name: "reset-password",
			meta: {
				title: "SADT | Redefinir minha senha",
			},
		},
		{
			path: "/cadastros",
			component: () => import("./components/cadastros/Cadastros.vue"),
			name: "cadastros",
			meta: {
				title: "SADT | Meu usuario",
				requirestAuth: true,
			},
		},
		{
			path: "/cadastro/:id",
			component: () => import("./components/cadastros/Cadastros.vue"),
			name: "cadastro",
			props: true,
			meta: {
				title: "SADT | Cadastros",
				requirestAuth: true,
			},
		},
		{
			path: "/evolucoes",
			component: () => import("./components/evolucoes/EvolucoesExpand.vue"),
			name: "evolucoes",
			meta: {
				title: "SADT | Evoluções",
				requirestAuth: true,
			},
		},
		{
			path: "/notas-fiscais",
			component: () => import("./components/notafiscal/NotaFiscal.vue"),
			name: "notas-fiscais",
			meta: {
				title: "SADT | Nota Fiscal",
				requirestAuth: true,
			},
		},
		{
			path: "/atendimentos",
			component: () => import("./components/atendimentos/Atendimentos.vue"),
			name: "atendimentos",
			meta: {
				title: "SADT | Atendimentos",
				requirestAuth: true,
			},
		},
		{
			path: "/evolucoes-gestao",
			component: () => import("./components/evolucoes/Guide.vue"),
			name: "evolucoes-gestao",
			meta: {
				title: "SADT | Gestão Evoluções",
				requirestAuth: true,
			},
		},
		{
			path: "/suporte",
			component: () => import("./components/chats/Chat.vue"),
			name: "suporte",
			meta: {
				title: "SADT | Chat",
				requirestAuth: true,
			},
		},
		{
			path: "/faturamento",
			component: () => import("./components/faturamento/Faturamento.vue"),
			name: "faturamento",
			meta: {
				title: "SADT | Faturamento",
				requirestAuth: true,
			},
		},
		{
			path: "/relatorio-procucao",
			component: () =>
				import("./components/relatorioproducao/RelatorioProducao.vue"),
			name: "relatorio-procucao",
			meta: {
				title: "SADT | Relatorio Producao",
				requirestAuth: true,
			},
		},
		{
			path: "/log",
			component: () => import("./components/log/Log.vue"),
			name: "log",
			meta: {
				title: "SADT | Log",
				requirestAuth: true,
			},
		},
		{
			path: "/acompanhamento-coleta-assinaturas",
			component: () => import("./components/acompanhamento/Acompanhamento.vue"),
			name: "acompanhamento-coleta-assinaturas",
			meta: {
				title: "SADT | Acompanhamento Coleta Assinatura",
				requirestAuth: true,
			},
		},
		{
			path: "/sessoes",
			component: () => import("./components/orcamento/Sessoes.vue"),
			name: "sessoes",
			meta: {
				title: "SADT | Evoluções",
				requirestAuth: true,
			},
		},
		{
			path: "/sessao/:id",
			component: () => import("./components/orcamento/Sessoes.vue"),
			name: "sessao",
			props: true,
			meta: {
				title: "SADT | Evoluções",
				requirestAuth: true,
			},
		},
		{
			path: "/geolocalizacao/:id/:username",
			component: () =>
				import(
					"./components/cadastros/componentes/pacientes/Geolocalizacao.vue"
				),
			name: "geolocalizacao",
			meta: {
				title: "SADT | map",
				requirestAuth: true,
			},
		},
		{
			path: "/equipe_multi",
			component: () => import("./components/frontmulti/MenuMulti.vue"),
			name: "equipe_multi",
			meta: {
				title: "SADT | Equipe Mult",
				requirestAuth: true,
			},
		},

		// Abaixo os links para as pastas da equipe Multiprofissional

		{
			path: "/evolucao_equipe_multi",
			component: () =>
				import(
					"./components/frontmulti/componentesEquipe/lancamentoEvolucao/evolucao.vue"
				),
			name: "evolucao_equipe_multi",
			meta: {
				title: "SADT | Equipe Mult",
				requirestAuth: true,
			},
		},
		{
			path: "/coleta_assinatura",
			component: () =>
				import(
					"./components/frontmulti/componentesEquipe/coletaAssinatura/coletaAssinatura.vue"
				),
			name: "coleta_assinatura",
			meta: {
				title: "SADT | Equipe Mult",
				requirestAuth: true,
			},
		},
		{
			path: "/nota_fiscal_equipe",
			component: () =>
				import(
					"./components/frontmulti/componentesEquipe/lancamentoNotaFiscal/notafiscalequipe.vue"
				),
			name: "nota_fiscal_equipe",
			meta: {
				title: "SADT | Equipe Mult",
				requirestAuth: true,
			},
		},
		{
			path: "/prontuario_paciente",
			component: () =>
				import(
					"./components/frontmulti/componentesEquipe/prontuarioPaciente/prontuario_paciente.vue"
				),
			name: "prontuario_paciente",
			meta: {
				title: "SADT | Equipe Mult",
				requirestAuth: true,
			},
		},
		{
			path: "/producao_produtividade_equipe_multi.vue",
			component: () =>
				import(
					"./components/frontmulti/componentesEquipe/produtividadeEquipeMulti/producao_produtividade_equipe_multi.vue"
				),
			name: "producao_produtividade_equipe_multi.vue",
			meta: {
				title: "SADT | Equipe Mult",
				requirestAuth: true,
			},
		},
		{
			path: "/dadospessoais_equipe_multi",
			component: () =>
				import(
					"./components/frontmulti/componentesEquipe/meusDados/dadospessoais_equipe_multi.vue"
				),
			name: "dadospessoais_equipe_multi",
			meta: {
				title: "SADT | Equipe Mult",
				requirestAuth: true,
			},
		},
		{
			path: "/chat",
			component: () => import("./components/chats/Chat.vue"),
			name: "chat",
			meta: {
				title: "SADT | Suporte",
				requirestAuth: true,
			},
		},
		{
			path: "/chat-admin",
			component: () => import("./components/chats/admin/ChatAdmin.vue"),
			name: "chatadmin",
			meta: {
				title: "SADT | Suporte Admin",
				requirestAuth: true,
			},
		},
		{
			path: "/faturamentoresumo",
			component: () => import("./components/faturamentoresumo/Faturamento.vue"),
			name: "faturamentoresumo",
			meta: {
				title: "SADT | Acompanhamento do Faturamento",
				requirestAuth: true,
			},
		},
		{
			path: "/equipamento",
			component: () => import("./components/suprimento/GestaoEquipamento.vue"),
			name: "equipamento",
			meta: {
				title: "SADT | Equipamento",
				requirestAuth: true,
			},
		},
		{
			path: "/monitoramento",
			component: () => import("./components/suprimento/Equipamentos.vue"),
			name: "monitoramento",
			meta: {
				title: "SADT | Monitoramento",
				requirestAuth: true,
			},
		},
		{
			path: "/indicadores",
			component: () => import("./components/indicadores/PainelIndicadores.vue"),
			name: "indicadores",
			meta: {
				title: "SADT | Indicadores",
				requirestAuth: true,
			},
		},
		//{ path:'*', redirect: '/' }
	],
});

router.beforeEach((to, from, next) => {
	if (to.meta.requirestAuth) {
		const { accessToken } = getCookies();
		if (!accessToken) return next({ name: "login" });
		// var usertoken = localStorage.getItem("tokenAccess");
		/*var usu = JSON.parse(localStorage.getItem('cfgu'))||{usuario:{data:{trocar_senha:''}}}
    console.log(usu.usuario.data.trocar_senha)*/
		// if (usertoken) {
		//   document.title = to.meta.title;
		//   next();
		// } else {
		//   document.title = to.meta.title;
		//   next({ name: "login" });
		// }
	}
	next();
});

export { router };
